const ROUTE_PATH = {
  PAGE404: '*',
  LOGIN: '/login',
  JOIN_MATCH: '/join-match',
  SCORE_BOARD: '/score-board',
  FINAL_BOARD: '/final-board',
  LOADING: '/',
  PLAYER_JOINING: '/player-joining',
  GAME: '/game',
  GAME_DETAIL: '/game-detail',
};

const API_PATH = {
  GOOGLE_LOGIN: '/api/v1/auth/google',
  LOGIN: '/admin-login',
  GAME_LISTING: '/api/v1/games',
  USER: '/api/v1/user',
  DOWNLOAD_CSV: '/api/v1/game/download-csv',
  CREATE_PRIVATE_GAME: '/api/v1/game/private-game',
  GAME_DETAIL: '/api/v1/game'
};

export { ROUTE_PATH, API_PATH };
