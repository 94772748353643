import React, { useEffect, useState } from "react";
import "./playerJoining.scss";
import CircularProgress from "@mui/material/CircularProgress";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import LineImg from "../../assets/svg/line.svg";
import LoadingImg from "../../assets/svg/loading-img.svg";
import WinnerImg from "../../assets/svg/winner.svg";
import { useSocket } from "../../services/socketContext";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../constants/route";
import { Avatar } from "@mui/material";
import { ToastNotifyError } from "../../components/Toast/ToastNotify";

const PlayerJoining = () => {
  const socket = useSocket();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.authentication.login);
  const { data: { _id: userId = "" } = {} } = userData;
  const [gameData, setGameData] = useState({});

  // useEffect(() => {
  //   const handlePopState = () => {
  //     socket.emit("leaveGame", { userId, gameId: gameData?._id });
  //   };

  //   const handleBeforeUnload = (event) => {
  //     socket.emit("leaveGame", { userId, gameId: gameData?._id });
  //     // Some browsers require this to show a confirmation dialog
  //     event.preventDefault();
  //     event.returnValue = ""; // Required for Chrome
  //   };

  //   // Attach event listeners
  //   window.addEventListener("popstate", handlePopState);
  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     // Clean up the event listeners
  //     window.removeEventListener("popstate", handlePopState);
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  useEffect(() => {
    if (!socket) return;

    socket.on("error", (err) => {
      console.log("error", err);
      if (err) {
        ToastNotifyError(err);
        navigate(ROUTE_PATH.JOIN_MATCH);
      }
    });

    socket.on("gameStarted", (gameData) => {
      setGameData(gameData);
    });

    socket.on("gameJoined", (gameData) => {
      setGameData(gameData);
    });

    return () => {
      socket.off("error");
      socket.off("gameStarted");
      socket.off("gameJoined");
    };
  }, [socket]);

  useEffect(() => {
    if (gameData?.status === "IN_PROGRESS") {
      navigate(ROUTE_PATH.GAME);
    }
  }, [gameData, navigate]);

  return (
    <div className="player-joining">
      <div className="player-count">
        <div className="player-box">
          <PeopleAltIcon
            style={{ marginRight: "5px", width: "17px", height: "17px" }}
          />
          {gameData?.numberOfPlayers} Player
        </div>
        <div className="border">
          <img src={LineImg} alt="Line" />
        </div>
      </div>
      <div className="user-list">
        {gameData?.players?.map((item, index) => {
          return (
            <div className="user-view" key={index}>
              <div className="user-img">
                <Avatar
                  alt={item?.user?.name}
                  src={item?.user?.imageUrl || LoadingImg}
                  className="user-img"
                />
                {/* <img
                  src={item?.user?.imageUrl || LoadingImg}
                  alt="user-image"
                /> */}
              </div>
              <div className="user-text">
                {userId === item?.user?._id ? "You" : item?.user?.name}
              </div>
            </div>
          );
        })}
      </div>
      <div className="score-box">
        <div className="win-amount">
          <img
            src={WinnerImg}
            alt="winner"
            style={{ width: "15px", height: "15px", marginRight: "5px" }}
          />{" "}
          Winning
        </div>
        <div className="amount">₹{gameData?.firstWinnerCoins}</div>
      </div>
      <div className="loading-box">
        <CircularProgress
          sx={{
            color: "white",
          }}
          size={20}
        />
        <div className="loading-text">Finding Opponents for the Match..</div>
      </div>
    </div>
  );
};

export default PlayerJoining;
