import axios from 'axios';
import { ToastNotifyError } from '../components/Toast/ToastNotify';

const client = () => {
  const defaultOptions = {
    headers: {},
  };
  const API = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });

  API.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('auth');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  API.interceptors.response.use(
    (response) => response,
    (error) => {
      const { response: { data: { message } = {}, status } = {} } = error;
      if (status === 401) {
        // Auth Failed Logout
        window.localStorage.clear();
        window.sessionStorage.clear();
        window.location.href = '/';
        ToastNotifyError(message);
      } else if (parseInt(status / 100) === 4) {
        ToastNotifyError(message);
      } else if (parseInt(status / 100) === 5) {
        // Sever Failed (Something Went Worng)
        ToastNotifyError(message);
      }
    },
  );

  return {
    get: (url, data, options = {}) => API.get(url, data, { ...defaultOptions, ...options }),
    post: (url, data, options = {}) => API.post(url, data, { ...defaultOptions, ...options }),
    patch: (url, data, options = {}) => API.patch(url, data, { ...defaultOptions, ...options }),
    put: (url, data, options = {}) => API.put(url, data, { ...defaultOptions, ...options }),
    delete: (url, options = {}) => API.delete(url, { ...defaultOptions, ...options }),
  };
};

export default client;
