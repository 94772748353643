import React, { useEffect, useState } from "react";
import "./game.scss";
import Avatar from "@mui/material/Avatar";
// import defaultCard from "../../assets/images/cards/defaultCard.png";
import cardNumber from "../../assets/images/cardNumber.png";
import { PrimaryButton, SecondaryButton } from "../../components/Button";
import ball from "../../assets/images/ball.svg";
import passScoreBall from "../../assets/images/passScoreBall.png";
import cardTokenCount from "../../assets/images/cardTokenCount.svg";
import cardsCount from "../../assets/svg/cardsCount.svg";
import { useSocket } from "../../services/socketContext";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../constants/route";
import { updateScoreBoard } from "../../store/reducers/authentication";
import cardJsonData from "./playerCard.json";
import Tooltip from "@mui/material/Tooltip";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import SwipeableEdgeDrawer from "../../components/Drawer";

export default function Game() {
  const socket = useSocket();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authentication.login);
  const { data: { _id: userId = "" } = {} } = userData;

  const [fetchedGameData, setFetchedGameData] = useState({});
  const [playerData, setPlayersData] = useState([]);
  const [currentPlayerChangeIndex, setCurrentPlayerChanceIndex] = useState("");
  const [totalPlayers, setTotalPlayers] = useState(0);
  const [timeLeft, setTimeLeft] = useState(10);
  const [open, setOpen] = React.useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [isCardPicked, setIsCardPicked] = useState(false);
  const toggleDrawer = (newOpen) => {
    setOpen(newOpen);
  };
  useEffect(() => {
    if (!isCardPicked) {
      if (timeLeft === 0) {
        setTimeLeft(0);
        handlePickCard();
        return;
      }
      const timerId = setInterval(() => {
        if (!isCardPicked) {
          setTimeLeft((prevTime) => prevTime - 1);
        }
      }, 1000);
      return () => {
        clearInterval(timerId);
      };
    }
  }, [timeLeft]);

  const contructDataformat = (gameData = {}) => {
    const currentPlayerChance = gameData?.players?.findIndex(
      (item) => item.user?._id === userId
    );
    setCurrentPlayerChanceIndex(currentPlayerChance);
    let pushAfter;
    switch (gameData?.numberOfPlayers) {
      case 7:
      case 6:
      case 5:
      case 4:
        pushAfter = (currentPlayerChance + 2) % gameData?.players?.length;
        break;
      default:
        pushAfter = (currentPlayerChance + 1) % gameData?.players?.length;
        break;
    }
    const newArray = [];
    const totalPlayersCount = gameData?.players?.length;
    for (let i = 0; i < totalPlayersCount; i++) {
      const index = (pushAfter + i) % totalPlayersCount;
      newArray.push(gameData?.players[index]);
    }
    setPlayersData(newArray);
  };

  useEffect(() => {
    if (!socket) return;

    socket.on("error", (err) => {
      console.log("error", err);
    });

    socket.on("gameUpdated", (gameData) => {
      setFetchedGameData(gameData);
      setTotalPlayers(gameData?.numberOfPlayers);
      contructDataformat(gameData);
      setTimeLeft(10);
      setIsCardPicked(false);
    });

    socket.on("gameEnded", (gameData) => {
      dispatch(updateScoreBoard(gameData));
      setFetchedGameData(gameData);
      contructDataformat(gameData);
      if (gameData?.status === "COMPLETED") {
        navigate(ROUTE_PATH?.SCORE_BOARD);
      }
    });

    socket.on("pickingCard", (user) => {
      setIsCardPicked(true);
    });

    return () => {
      socket.off("error");
      socket.off("gameUpdated");
      socket.off("gameEnded");
    };
  }, [socket]);

  const headerPlayersCount = () => {
    switch (fetchedGameData?.numberOfPlayers) {
      case 7:
        return 4;
      case 6:
      case 5:
        return 3;
      case 4:
      case 3:
        return 2;
      default:
        return 0;
    }
  };

  function processArray(arr) {
    arr?.sort((a, b) => a - b);
    let result = [];
    let currentRange = [arr[0]];

    for (let i = 1; i < arr.length; i++) {
      if (arr[i] === arr[i - 1] + 1) {
        currentRange.push(arr[i]);
      } else {
        result.push(currentRange);
        currentRange = [arr[i]];
      }
    }
    result.push(currentRange);
    return result;
  }

  const renderOtherPlayer = (
    cardPostion = "",
    currentUser = false,
    data = {}
  ) => {
    const sumPoints = data?.cards?.length
      ? processArray(data?.cards)
          .map((subArray) => subArray[0])
          .reduce((acc, curr) => acc + curr, 0)
      : 0;
    const showTimer =
      fetchedGameData?.players[fetchedGameData?.currentPlayer]?.user?._id ===
      data?.user?._id;
    const showTurn =
      fetchedGameData?.players[fetchedGameData?.currentPlayer]?.user?._id ===
        data?.user?._id && currentUser === false;
    return (
      <div
        className={`otherUserAvatar-wrap  ${
          currentUser ? "you-content-wrap" : ""
        }`}
        key={data?._id}
      >
        {cardPostion === "top" ? (
          <Tooltip
            title={
              isCardPicked ? (
                <>
                  <span className="lightText">I’m picking up </span>
                  <br />
                  <strong className="dark">
                    {
                      cardJsonData[
                        fetchedGameData?.currentCard - 1
                      ]?.playerName?.split(" ")[0]
                    }
                  </strong>
                </>
              ) : (
                "My Turn"
              )
            }
            placement="top"
            arrow
            open={showTurn}
          >
            <div
              className="card-wrap"
              style={{ marginBottom: currentUser ? "15px" : "5px" }}
              onClick={() => {
                toggleDrawer(true);
                setUserInfo(data);
              }}
            >
              {data?.cards.length
                ? processArray(data?.cards).map((arr) => {
                    if (arr.length > 1) {
                      return (
                        <div className="seq-card-wrap">
                          {arr.map((val, index) => {
                            return (
                              <div
                                className={`card ${
                                  index > 0 ? "card-shadow" : ""
                                }`}
                              >
                                {val}
                              </div>
                            );
                          })}
                        </div>
                      );
                    } else {
                      return <div className="card">{arr[0]}</div>;
                    }
                  })
                : ""}
            </div>
          </Tooltip>
        ) : (
          ""
        )}
        <div className={`avatar-wrap ${currentUser ? "self" : ""}`}>
          <Avatar alt={data?.user?.name} src={data?.user?.imageUrl} />
          <div className="name">
            {currentUser ? "You" : (data?.user?.name).split(" ")[0]}
          </div>
          {showTimer ? (
            <>
              <div className="timer">{timeLeft}</div>
              <div className="progressCircularWrap">
                <CircularProgressbar
                  value={timeLeft * 10}
                  strokeWidth={50}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                  })}
                />
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        {currentUser ? (
          <div className="footer-container">
            <div className="footer-wrap">
              <div className="points">
                <span className="points-val">
                  {sumPoints - data?.chips > 0 ? sumPoints - data?.chips : 0}
                </span>{" "}
                pts
              </div>
              <div className="totalCardsWrap">{data?.cards?.length || 0}</div>
              <img src={cardsCount} alt="" className="cardsCount" />
            </div>
            <div className="points points-val ">
              {data?.chips}
              <img src={passScoreBall} alt="" className="passScoreBall" />
            </div>
          </div>
        ) : (
          <div className="footer-wrap">
            <div className="points">
              <span className="points-val">{sumPoints || 0}</span> pts
            </div>
            <div className="totalCardsWrap">{data?.cards?.length || 0}</div>
            <img src={cardsCount} alt="" className="cardsCount" />
          </div>
        )}
        {cardPostion === "bottom" ? (
          <Tooltip
            title={
              isCardPicked ? (
                <>
                  <span className="lightText">I’m picking up </span>
                  <br />
                  <strong className="dark">
                    {
                      cardJsonData[
                        fetchedGameData?.currentCard - 1
                      ]?.playerName?.split(" ")[0]
                    }
                  </strong>
                </>
              ) : (
                "My Turn"
              )
            }
            placement="bottom"
            arrow
            open={showTurn}
          >
            <div
              className="card-wrap"
              style={{ marginTop: "5px" }}
              onClick={() => {
                toggleDrawer(true);
                setUserInfo(data);
              }}
            >
              {data?.cards.length
                ? processArray(data?.cards).map((arr) => {
                    if (arr.length > 1) {
                      return (
                        <div className="seq-card-wrap">
                          {arr.map((val, index) => {
                            return (
                              <div
                                className={`card ${
                                  index > 0 ? "card-shadow" : ""
                                }`}
                              >
                                {val}
                              </div>
                            );
                          })}
                        </div>
                      );
                    } else {
                      return <div className="card">{arr[0]}</div>;
                    }
                  })
                : ""}
            </div>
          </Tooltip>
        ) : (
          ""
        )}
      </div>
    );
  };

  const handlePassCard = () => {
    socket.emit("passCard", { gameId: fetchedGameData?._id });
  };

  const handlePickCard = () => {
    socket.emit("takeCard", { gameId: fetchedGameData?._id });
  };
  return (
    <div className="game-screen-wrap">
      <div className="header-wrap">
        {playerData?.slice(0, headerPlayersCount())?.map((item) => {
          return renderOtherPlayer("bottom", false, item);
        })}
      </div>
      <div className="content-wrap">
        <div className="card-wrap">
          <img
            src={cardJsonData[fetchedGameData?.currentCard - 1]?.image}
            alt=""
          />
          {/* <img src={defaultCard} alt="" /> */}
          {fetchedGameData?.chipsOnCard ? (
            <div className="cardTokenCount-wrap">
              x{fetchedGameData?.chipsOnCard}
              <img
                src={cardTokenCount}
                alt=""
                className="cardTokenCountImage"
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="card-number-wrap">
          {fetchedGameData?.deck?.length}
          <img src={cardNumber} alt="" className="cardNumberImage" />
        </div>
        {fetchedGameData?.currentPlayer === currentPlayerChangeIndex ? (
          <div className="buttons-wrap">
            <SecondaryButton
              sx={{
                padding: "13px 16px",
                fontSize: "16px",
              }}
              onClick={() => handlePassCard()}
              disabled={
                (fetchedGameData?.players &&
                  fetchedGameData?.players[fetchedGameData?.currentPlayer] &&
                  fetchedGameData?.players[fetchedGameData?.currentPlayer]
                    ?.chips === 0) ||
                isCardPicked
              }
            >
              <img src={ball} alt="" />
              PASS
            </SecondaryButton>
            <PrimaryButton
              sx={{
                padding: "15px 16px",
                fontSize: "16px",
              }}
              onClick={() => handlePickCard()}
              disabled={isCardPicked}
            >
              PICK
            </PrimaryButton>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="footer-wrap">
        {playerData
          ?.slice(headerPlayersCount())
          ?.reverse()
          ?.map((item, index) => {
            return renderOtherPlayer("top", item?.user?._id === userId, item);
          })}
      </div>
      {open && (
        <SwipeableEdgeDrawer
          open={open}
          toggleDrawer={toggleDrawer}
          userInfo={userInfo}
        />
      )}
    </div>
  );
}
